import React, {Suspense} from 'react';
//import Axios from 'axios'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import './styles/app.scss'

const Auth = React.lazy(() => import('./components/auth'))
const Session = React.lazy(() => import('./components/session'))

const waiting = (
  <div
    style={{width           :"100%",
            height          :"100vh",
            backgroundColor :"#F8F8F8",
            alignItems      :"center",
            justifyContent  :"center",
            display         :"flex"}}
  >
    <div className="spinner-border"></div>
  </div>
)

export class App extends React.Component {

  WaitingComponent(Component, data={}) {
    return props => (
      <Suspense fallback={waiting}>
        <Component {...props} data={data}/>
      </Suspense>
    );
  }

  render(){
    return (
      <Router> 
        <Switch>
          <Route exact  path="/restrito/:op" component={this.WaitingComponent(Session)}/>
          <Route exact  path="/restrito" component={this.WaitingComponent(Session)}/>
          <Route  path="/" component={this.WaitingComponent(Auth)}/>
        </Switch>
      </Router>
  );
  } 
}

export default App;
